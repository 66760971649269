import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WOW from 'wowjs';
import FixedIcons from './FixedIcons';
import Footer from './Footer';
import Header from './Header';

function BaseLayout(props) {
  const { children } = props;

  const location = useLocation();

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, [location.pathname]);

  return (
    <>
      <FixedIcons />
      <Header />
      {children}
      <Footer />
    </>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
