import React from 'react';
import CONFIG from '../core/config';
import { initPhoneNumber } from '../utils';

function FixedIcons() {
  return (
    <div className='fixed-icons'>
      <a
        href={`https://api.whatsapp.com/send?phone=${initPhoneNumber(
          CONFIG.phone
        )}`}
        title='whatsapp'
        className='whatsapp'
        target='_blank'
        rel='noreferrer'
      >
        <i className='bi bi-whatsapp'></i>
      </a>

      <a
        href={`tel:${initPhoneNumber(CONFIG.phone)}`}
        target='_blank'
        rel='noreferrer'
        title='telephone'
      >
        <i className='bi bi-telephone'></i>
      </a>
    </div>
  );
}

export default FixedIcons;
