import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/common/Breadcrumb';

function Faq() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb
        pageName={t('terms-and-conditions')}
        pageTitle={t('terms-and-conditions')}
      />

      <div className='container pt-120 pb-120'>
        <p className='text-start mb-5'>
          Please read these Terms and Conditions carefully before using our
          Service. Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service. By
          accessing or using the Service you agree to be bound by these Terms.
          If you disagree with any part of the terms then you may not access the
          Service.
        </p>

        <div
          className='faq-wrap wow fadeInUp'
          data-wow-duration='1.5s'
          data-wow-delay='.2s'
        >
          <div className='accordion' id='accordionExample'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Accounts
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    When you create an Account with us, you must provide us
                    information that is accurate, complete, and current at all
                    times. Failure to do so constitutes a breach of the Terms,
                    which may result in immediate termination of your Account on
                    our Service. You are responsible for safeguarding the
                    password that you use to access the Service and for any
                    activities or actions you take on the Service that occur
                    under your password, whether your password is with our
                    Service or a third-party service. You agree not to disclose
                    your password to any third party. You must notify us
                    immediately upon becoming aware of any breach of security or
                    unauthorized use of your account.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  Links To Other Web Sites
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by Dynamic
                    Business Solutions. Dynamic Business Solutions has no
                    control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third party
                    web sites or services. You further acknowledge and agree
                    that Dynamic Business Solutions shall not be responsible or
                    liable, directly or indirectly, for any damage or loss
                    caused or alleged to be caused by or in connection with use
                    of or reliance on any such content, goods or services
                    available on or through any such web sites or services. We
                    strongly advise you to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that you visit.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  Termination
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We may terminate or suspend access to our Service
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. All provisions of the Terms which by their
                    nature should survive termination shall survive termination,
                    including, without limitation, ownership provisions,
                    warranty disclaimers, indemnity and limitations of
                    liability. We may terminate or suspend your account
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. Upon termination, your right to use the
                    Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability. We
                    reserve the right to terminate or suspend your account
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. Upon termination, your right to use the
                    Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability. We
                    reserve the right to terminate or suspend your account
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. Upon termination, your right to use the
                    Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability. We
                    reserve the right to terminate or suspend your account
                    immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if you
                    breach the Terms. Upon termination, your right to use the
                    Service will immediately cease. If you wish to terminate
                    your account, you may simply discontinue using the Service.
                    All provisions of the Terms which by their nature should
                    survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty
                    disclaimers, indemnity and limitations of liability. We
                    reserve the right to terminate
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFour'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFour'
                  aria-expanded='false'
                  aria-controls='collapseFour'
                >
                  Governing Law
                </button>
              </h2>
              <div
                id='collapseFour'
                className='accordion-collapse collapse'
                aria-labelledby='headingFour'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    These Terms shall be governed and construed in accordance
                    with the laws of the State of Kuwait, without regard to its
                    conflict of law provisions. Our failure to enforce any right
                    or provision of these Terms will not be considered a waiver
                    of those rights. If any provision of these Terms is held to
                    be invalid or unenforceable by a court, the remaining
                    provisions of these Terms will remain in effect. These Terms
                    constitute the entire agreement between us regarding our
                    Service, and supersede and replace any prior agreements we
                    might have between us regarding the Service.
                  </p>
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFive'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFive'
                  aria-expanded='false'
                  aria-controls='collapseFive'
                >
                  Changes
                </button>
              </h2>
              <div
                id='collapseFive'
                className='accordion-collapse collapse'
                aria-labelledby='headingFive'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  <p className='text-start'>
                    We reserve the right, at our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    we will try to provide at least 30 days notice prior to any
                    new terms taking effect. What constitutes a material change
                    will be determined at our sole discretion. By continuing to
                    access or use our Service after any revisions become
                    effective, you agree to be bound by the revised terms. If
                    you do not agree to the new terms, you are no longer
                    authorized to use the Service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className='text-start mt-5'>
          If you have any questions about these Terms, please contact us.
        </p>
      </div>
    </>
  );
}

export default Faq;
