import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import certificateImage from '../assets/images/icons/certificate.png';
import aboutImage from '../assets/images/background/about.png';
import heroImage from '../assets/images/background/bg-hero-image.png';
import frappeCertificateImage from '../assets/images/background/frappe-certificate.png';
import AboutUsCounter from '../components/common/AboutUsCounter';
import Services from '../components/common/Services';
import Solutions from '../components/common/Solutions';
import SponsorArea from '../components/common/SponsorArea';
import Testimonial from '../components/common/Testimonial';
import { scrollToTop } from '../utils';
import { UseI18nContext } from '../context/i18n';

function HomePage3() {
  const { t } = useTranslation();
  const i18nContext = UseI18nContext();

  return (
    <>
      <div className='hero-area hero-style-three'>
        <img
          alt='bannerImage'
          src={heroImage}
          className='home3-banner img-fluid'
        />

        <div className='container-lg container-fluid'>
          <div className='row d-flex justify-content-start align-items-end'>
            <div className='col-xl-6 col-lg-5 px-0'>
              <div className='banner3-content'>
                <h1
                  className='wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='1s'
                >
                  {t('hero-title')}
                </h1>

                <span
                  className='wow fadeInDown text-gray mb-5'
                  data-wow-duration='1.5s'
                  data-wow-delay='0.5s'
                >
                  {t('hero-subtitle')}
                </span>

                <Link
                  to='/contact'
                  onClick={scrollToTop}
                  className='eg-btn btn--primary3 btn--lg wow fadeInUp'
                  data-wow-duration='2s'
                  data-wow-delay='0.8s'
                >
                  {t('request-a-demo-version')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='my-5'>
        <AboutUsCounter />
      </div>

      <div className='about-section pt-5 mt-5 overflow-hidden'>
        <div className='container'>
          <div className='row align-items-center g-4'>
            <div className='col-lg-7'>
              <div
                className='about-content wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
              >
                <h2> {t('about-dynamic')} </h2>
                <p className='para'>{t('about-full-info')} </p>
                <Link
                  to='/about'
                  onClick={scrollToTop}
                  className='eg-btn btn--primary3 btn--lg wow fadeInUp'
                  data-wow-duration='2s'
                  data-wow-delay='0.8s'
                >
                  {t('read-more')}
                </Link>
              </div>
            </div>

            <div className='col-lg-5'>
              <div className='about-img-area'>
                <img
                  src={aboutImage}
                  className='img-fluid about-img wow fadeInUp'
                  data-wow-duration='1.5s'
                  data-wow-delay='.2s'
                  alt='about-img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='about-section overflow-hidden pt-120 certificate-section '>
        <div className='container pb-60'>
          <div className='row d-flex justify-content-center align-items-center g-4'>
            <div className='col-md-6'>
              <div className='d-flex justify-content-center mb-3'>
                <img
                  src={certificateImage}
                  alt='certificate'
                  width={64}
                  height={64}
                />
              </div>

              <div className='text-center'>
                {i18nContext.language.code === 'ar' && (
                  <h2>شريك معتمد لـ Frappe (ERPNext)</h2>
                )}

                <h3>Official Certified Partner ERPNext</h3>

                <p className='para text-dark mt-4'>
                  {t('frappe-certificate-info')}
                </p>

                <div
                  className='sidebar-widget-title mb-2 mt-5 wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='.5s'
                >
                  <h5>{t('our-services-as-a-partner')}</h5>
                </div>

                <p
                  className='para wow fadeInDown'
                  data-wow-duration='1.5s'
                  data-wow-delay='.5s'
                >
                  {t(
                    'system-implementation-and-installation-training-and-support-customization-and-development'
                  )}
                </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='about-img-area'>
                <img
                  src={frappeCertificateImage}
                  className='img-fluid about-img wow fadeInUp'
                  data-wow-duration='1.5s'
                  data-wow-delay='.2s'
                  alt='about-img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Solutions />

      <div className='live-auction mt-50 pb-60'>
        <div className='container position-relative'>
          <div className='row d-flex justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
              <div className='section-title4'>
                <h2>{t('our-services')}</h2>
                <p className='mb-0'>
                  {t(
                    'we-will-help-you-to-optimize-the-running-costs-of-your-business'
                  )}
                </p>
              </div>
            </div>
          </div>

          <Services />
        </div>
      </div>

      <Testimonial />

      <SponsorArea />
    </>
  );
}

export default HomePage3;
