import React from 'react';
import { useTranslation } from 'react-i18next';
import { SPONSOR } from '../core/data';
import AboutUsCounter from '../components/common/AboutUsCounter';
import Breadcrumb from '../components/common/Breadcrumb';

function Clients() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb pageName={t('our-clients')} pageTitle={t('our-clients')} />

      <div className='sponsor-section style-3'>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
              <div className='section-title1'>
                <h2>
                  {t('trusted-by-more-than-250-active-clients-middle-east')}
                </h2>
              </div>
            </div>
          </div>
          <div
            className='wow fadeInUp'
            data-wow-duration='1.5s'
            data-wow-delay='0.2s'
          >
            <div className='row g-4 justify-content-center'>
              {SPONSOR.map((item, index) => (
                <div className='col-6 col-sm-4 col-md-3 col-lg-2' key={item}>
                  <img
                    alt='client'
                    src={item}
                    style={{ maxWidth: '100%' }}
                    width={160}
                    height={90}
                    className='wow fadeInDown'
                    data-wow-duration='1.5s'
                    data-wow-delay={`${(0.2 * index) % 3}s`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='pt-120 pb-120'>
        <AboutUsCounter />
      </div>
    </>
  );
}

export default Clients;
