import React, { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WOW from 'wowjs';
import { I18nContextProvider } from '../context/i18n';
import BaseLayout from '../layout/BaseLayout';
import Preloader from '../layout/Preloader';
import About from '../pages/About';
import Clients from '../pages/Clients';
import Contact from '../pages/Contact';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import PrivacyAndPolicy from '../pages/PrivacyAndPolicy';
import Solutions from '../pages/Solutions';
import Dynamic from '../pages/solutions/Dynamic';
import ERPNextSolution from '../pages/solutions/ERPNext';
import NovaHR from '../pages/solutions/NovaHR';
import TermsAndConditions from '../pages/TermsAndConditions';

import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import '../assets/scss/base.scss';

function App() {
  useLayoutEffect(() => {
    // remove loading after content load
    window.onload = function () {
      document.body.classList.remove('loading');

      new WOW.WOW({
        live: false,
      }).init();
    };
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <I18nContextProvider>
        <BrowserRouter>
          <BaseLayout>
            <Switch>
              <Route exact path='/' component={HomePage} />
              <Route exact path='/about' component={About} />
              <Route exact path='/contact' component={Contact} />
              <Route exact path='/clients' component={Clients} />
              <Route exact path='/solutions' component={Solutions} />
              <Route
                exact
                path='/solution-details/erp-next'
                component={ERPNextSolution}
              />
              <Route
                exact
                path='/solution-details/nova-hr'
                component={NovaHR}
              />
              <Route
                exact
                path='/solution-details/dynamic'
                component={Dynamic}
              />
              <Route
                exact
                path='/terms-conditions'
                component={TermsAndConditions}
              />
              <Route
                exact
                path='/privacy-policy'
                component={PrivacyAndPolicy}
              />
              <Route path='*' component={ErrorPage} />
            </Switch>
          </BaseLayout>
        </BrowserRouter>
      </I18nContextProvider>
    </Suspense>
  );
}

export default App;
