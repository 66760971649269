import React from 'react';
import { useTranslation } from 'react-i18next';
import aboutImage from '../../assets/images/background/about.png';

function WhoWeAre() {
  const { t } = useTranslation();

  return (
    <div className='about-section pt-120 overflow-hidden'>
      <div className='container'>
        <div className='row d-flex justify-content-center g-4'>
          <div className='col-lg-7'>
            <div
              className='about-content wow fadeInDown'
              data-wow-duration='1.5s'
              data-wow-delay='.2s'
            >
              <h2> {t('about-dynamic')} </h2>
              <p className='para'>{t('about-full-info')} </p>

              <div
                className='sidebar-widget-title mb-2 mt-5 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.5s'
              >
                <h5> {t('our-goals')}</h5>
                <span></span>
              </div>

              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.5s'
              >
                {t('our-goals-info')}
              </p>

              <div
                className='sidebar-widget-title mb-2 mt-2 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.8s'
              >
                <h5> {t('our-misson')}</h5>
                <span></span>
              </div>
              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.8s'
              >
                {t('our-misson-info')}
              </p>

              <div
                className='sidebar-widget-title mb-2 mt-2 wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='1.2s'
              >
                <h5> {t('our-vision')}</h5>
                <span></span>
              </div>
              <p
                className='para wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='1.2s'
              >
                {t('our-vision-info')}
              </p>

              <div
                className='tab-pane fade show active'
                id='v-pills-dashboard'
                role='tabpanel'
                aria-labelledby='v-pills-dashboard-tab'
              ></div>
            </div>
          </div>

          <div className='col-lg-5'>
            <div className='about-img-area'>
              <img
                src={aboutImage}
                className='img-fluid about-img wow fadeInUp'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
                alt='about-img'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
