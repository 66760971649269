import React from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TESTIMONIAL } from '../../core/data';

SwiperCore.use([Navigation, Autoplay]);

function Testimonial() {
  const { t } = useTranslation();

  const testimonialSlider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    navigation: {
      nextEl: '.testi-prev1',
      prevEl: '.testi-next1',
    },
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 2,
      },
    },
  };

  return (
    <div className='testimonial-section pt-80 pb-80'>
      <img
        alt='testimonialBGImage'
        src='/images/bg/client-right.png'
        className='client-right-vector'
      />

      <img
        alt='testimonialBGImage'
        src='/images/bg/client-left.png'
        className='client-left-vector'
      />

      <img
        alt='testimonialBGImage'
        src='/images/bg/clent-circle1.png'
        className='client-circle1'
      />

      <img
        alt='testimonialBGImage'
        src='/images/bg/clent-circle2.png'
        className='client-circle2'
      />

      <img
        alt='testimonialBGImage'
        src='/images/bg/clent-circle3.png'
        className='client-circle3'
      />

      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
            <div className='section-title4'>
              <h2>{t('what-client-say')}</h2>
              <p className='mb-0'>{t('user-friendly-interface-our-customer-experience-is-the-foundation-of-everything-we-offer-and-we-always-strive-to-improve-it-and-make-it-exceptionally-special')} </p>
            </div>
          </div>
        </div>

        <div className='row justify-content-center position-relative'>
          <Swiper {...testimonialSlider} className='swiper testimonial-slider'>
            <div className='swiper-wrapper'>
              {TESTIMONIAL.map((item) => (
                <SwiperSlide className='swiper-slide' key={item.id}>
                  <div
                    className='testimonial-single h-100 hover-border3 wow fadeInDown'
                    data-wow-duration='1.5s'
                    data-wow-delay='.2s'
                  >
                    <img
                      alt='images'
                      src='/images/icons/quote.svg'
                      className='quote-icon'
                    />
                    <div className='testi-img'>
                      <img alt='images' src={item.image} />
                    </div>

                    <div className='testi-content'>
                      <p
                        className={`para ${
                          item?.dir === 'left' ? 'text-start' : ''
                        }`}
                        dir={item?.dir === 'left' ? 'ltr' : 'rtl'}
                      >
                        {item.description}
                      </p>

                      <div className='testi-designation'>
                        <h5>{item.name}</h5>
                        <p>{item.job}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>

          <div className='slider-arrows testimonial2-arrow d-flex justify-content-between gap-3'>
            <div
              className='testi-prev1 style-3 swiper-prev-arrow'
              tabIndex={0}
              role='button'
              aria-label='Previous slide'
            >
              <i className='bi bi-arrow-left' />
            </div>

            <div
              className='testi-next1 style-3 swiper-next-arrow'
              tabIndex={0}
              role='button'
              aria-label='Next slide'
            >
              <i className='bi bi-arrow-right' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
