import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutUsCounter from '../components/common/AboutUsCounter';
import Breadcrumb from '../components/common/Breadcrumb';
import SolutionCard from '../components/common/SolutionCard';
import DATA from '../core/data';

function Services() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb
        pageName={t('our-solutions')}
        pageTitle={t('our-solutions')}
      />

      <div className='live-auction pb-120 pt-120'>
        <div className='container position-relative'>
          <div className='row gy-4 d-flex justify-content-center'>
            {DATA.solutions.map((item) => (
              <div className='col-lg-4 col-md-6 col-sm-10 ' key={item.id}>
                <SolutionCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='pb-120'>
        <AboutUsCounter />
      </div>
    </>
  );
}

export default Services;
