const CONFIG = {
  companyName: 'Dynamic Egypt',
  phone: '+201203333843',
  address: '34 مكرم عبيد، المنطقة السادسة، مدينة نصر، محافظة القاهرة 11765',
  englishAddress:
    '34 Makram Ebeid, Sixth District, Nasr City, Cairo Governorate 11765',
  email: 'info@dynamiceg.com',
  twitterUrl: '',
  facebookUrl: 'https://www.facebook.com/dynamiceg/',
  linkedInUrl:
    'https://eg.linkedin.com/company/dynamic-technology?original_referer=https%3A%2F%2Fwww.google.com%2F',
  ksaAddress: '7277 طـريق الملك فـهد - العليا ،بـرج الفيصلية الرياض',
  ksaEnglishAddress: '7727 king Fahd Road Faisaliah Tower Riyadh',
  ksaPhone: '+966 569422225',
  ksaEmail: 'info@dynamicksa.com',
};

export default CONFIG;
