import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import i18n from '../core/i18n';

const LANGUAGES = [
  {
    title: 'العربية',
    code: 'ar',
  },
  {
    title: 'English',
    code: 'en',
  },
];

const I18nContext = createContext({
  language: LANGUAGES[0],
  toggleLanguage: () => {},
});

const UseI18nContext = () => {
  return useContext(I18nContext);
};

const I18nContextProvider = (props) => {
  const [language, setLanguage] = useState(LANGUAGES[0]);

  useEffect(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');

    setLanguageLocal(i18nextLng);
  }, []);

  const setLanguageLocal = (code) => {
    i18n.changeLanguage(code);

    if (code === 'ar') {
      setLanguage(LANGUAGES[0]);
      document.body.classList.add('rtl');
      document.documentElement.lang = LANGUAGES[0].code;
      document.documentElement.dir = 'rlt';
    } else {
      setLanguage(LANGUAGES[1]);
      document.body.classList.remove('rtl');
      document.documentElement.dir = 'ltr';
      document.documentElement.lang = LANGUAGES[1].code;
    }
  };

  const toggleLanguage = () => {
    setLanguageLocal(language.code === 'ar' ? 'en' : 'ar');
  };

  const providerValue = useMemo(
    () => ({
      language,
      toggleLanguage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  return (
    <I18nContext.Provider value={providerValue}>
      {props.children}
    </I18nContext.Provider>
  );
};

export { I18nContext, UseI18nContext, I18nContextProvider };
