import React from 'react';
import i18n from './i18n';

/**
 * @typedef {object} Service
 * @property {string} title
 * @property {string} image
 * @property {number} id
 */
/**
 * @typedef {object} WhyChooseUs
 * @property {string} title
 * @property {string} image
 * @property {number} id
 */
/**
 * @typedef {object} Status
 * @property {string} title
 * @property {string} image
 * @property {number} endCount
 * @property {string} wowDuration
 * @property {string} wowDelay
 * @property {number} id
 */
/**
 * @typedef {object} Solution
 * @property {string} title
 * @property {string} image
 * @property {React.JSX.Element=} extraInfo
 * @property {string|React.JSX.Element} info
 * @property {string} id
 */
/**
 * @typedef {object} NovaHrModule
 * @property {string} title
 * @property {string} image
 * @property {number} id
 * @property {React.JSX.Element} content
 */
/**
 * @typedef {object} ErpnextModule
 * @property {string} title
 * @property {string} image
 * @property {number} id
 * @property {React.JSX.Element} content
 */
/**
 * @type {{ services: Service[], whyChooseUs: WhyChooseUs[], status: Status[], solutions: Solution[], novaHrModules: NovaHrModule[], erpnextModules: ErpnextModule[] }}
 */
const DATA = {
  services: [],
  whyChooseUs: [],
  status: [],
  solutions: [],
  novaHrModules: [],
  erpnextModules: [],
};

const updateI18nTranslation = () => {
  DATA.services = [
    {
      id: 1,
      title: i18n.t('training-and-implementation'),
      image: '/images/services/service-6.png',
    },
    {
      id: 2,
      title: i18n.t('hosting-services'),
      image: '/images/services/service-8.png',
    },
    {
      id: 3,
      title: i18n.t('technical-support'),
      image: '/images/services/service-2.png',
    },
    {
      id: 4,
      title: i18n.t('integration-and-connectivity'),
      image: '/images/services/service-3.png',
    },
    {
      id: 5,
      title: i18n.t('data-analysis'),
      image: '/images/services/service-5.png',
    },
    {
      id: 6,
      title: i18n.t('consultations'),
      image: '/images/services/service-1.png',
    },
    {
      id: 7,
      image: '/images/services/service-7.png',
      title: i18n.t('mobile-applications'),
    },
    {
      id: 8,
      image: '/images/services/service-4.png',
      title: i18n.t('information-security'),
    },
  ];

  DATA.whyChooseUs = [
    {
      id: 1,
      title: i18n.t('cloud-services'),
      image: '/images/why-us/image-2.png',
    },
    {
      id: 2,
      title: i18n.t('multiple-currencies-exchange-rate-automatically'),
      image: '/images/why-us/image-4.png',
    },
    {
      id: 3,
      title: i18n.t('we-have-experience-in-all-fields-and-industries'),
      image: '/images/why-us/image-5.png',
    },
    {
      id: 4,
      title: i18n.t('more-than-15-years-of-experience-in-the-software-field'),
      image: '/images/why-us/image-1.png',
    },
    {
      id: 5,
      title: i18n.t(
        'experience-in-providing-highly-efficient-after-sales-services'
      ),
      image: '/images/why-us/image-3.png',
    },
    {
      id: 6,
      title: i18n.t('easy-to-use-interface'),
      image: '/images/why-us/image-6.png',
    },
  ];

  DATA.status = [
    {
      id: 1,
      title: i18n.t('years-of-experience'),
      endCount: 15,
      image: '/images/icons/employee.svg',
      wowDuration: '1.5s',
      wowDelay: '0.2s',
    },
    {
      id: 2,
      title: i18n.t('projects'),
      endCount: 75,
      image: '/images/icons/review.svg',
      wowDuration: '1.5s',
      wowDelay: '0.4s',
    },
    {
      id: 3,
      title: i18n.t('our-clients'),
      endCount: 500,
      image: '/images/icons/smily.svg',
      wowDuration: '1.5s',
      wowDelay: '0.4s',
    },
    {
      id: 4,
      title: i18n.t('success-stories'),
      endCount: 450,
      image: '/images/icons/comment.svg',
      wowDuration: '1.5s',
      wowDelay: '0.6s',
    },
  ];

  DATA.solutions = [
    {
      id: 'erp-next',
      title: i18n.t('erp-next-system'),
      image: '/images/solutions/solution-2.png',
      info: i18n.t('solution-erp-next-info'),
      extraInfo: (
        <div className='describe-content'>
        <p className='para'>
          {i18n.t('solution-erp-next-info')}
        </p>
  
        <p className='para'> {i18n.t('features-of-erp-next')} </p>
  
        <ul className='describe-list'>
          <li>
            <strong> {i18n.t('user-friendly-interface')} </strong> : {i18n.t('user-friendly-interface-description')}
          </li>
          <li>
            <strong> {i18n.t('multi-language-and-multi-currency-support')} </strong> : {i18n.t('multi-language-and-multi-currency-support-description')}
          </li>
          <li>
            <strong> {i18n.t('comprehensive-reports')} </strong> : {i18n.t('comprehensive-reports-description')}
          </li>
          <li>
            <strong> {i18n.t('process-automation')} </strong> : {i18n.t('process-automation-description')}
          </li>
  
          <li>
            <strong> {i18n.t('data-analysis')} </strong> : {i18n.t('data-analysis-description')}
          </li>
          <li>
            <strong> {i18n.t('data-security')} </strong> : {i18n.t('data-security-description')}
          </li>
          <li>
            <strong> {i18n.t('system-integration')} </strong> : {i18n.t('system-integration-description')}
          </li>
          <li>
            <strong> {i18n.t('customization-and-expansion')} </strong> : {i18n.t('customization-and-expansion-description')}
          </li>
          <li>
            <strong> {i18n.t('mobile-applications')} </strong> : {i18n.t('mobile-applications-description')}
          </li>
        </ul>
      </div>
  
      ),
    },
    {
      id: 'nova-hr',
      title: i18n.t('nova-hr-system'),
      image: '/images/solutions/solution-3.png',
      info: i18n.t('solutions-nova-hr'),
      extraInfo: (
        <div className='describe-content'>
          <p className='para'>
            {i18n.t('hr-goal')}
          </p>

          <div className='row g-md-5'>
            <div className='col-md-5'>
              <ul className='describe-list'>
                <li>
                  <strong> {i18n.t('employee-data-management')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('recruitment-process-management')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('performance-management-and-evaluation')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('payroll-and-benefits-management')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('attendance-and-leave-management')} </strong>
                </li>
              </ul>
            </div>

            <div className='col-md-5'>
              <ul className='describe-list'>
                <li>
                  <strong> {i18n.t('employee-development-and-training')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('internal-relationship-management')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('hr-analytics-and-reporting')} </strong>
                </li>
                <li>
                  <strong> {i18n.t('compliance-and-governance')} </strong>
                </li>
              </ul>
            </div>
          </div>

          <p className='para mt-3'> {i18n.t('benefits-of-using-hr-systems')} </p>

          <ul className='describe-list'>
            <li>
              <strong> {i18n.t('increased-efficiency')} </strong> {i18n.t('increased-efficiency-description')} 
            </li>
            <li>
              <strong> {i18n.t('improved-data-accuracy')} </strong> {i18n.t('improved-data-accuracy-description')} 
            </li>
            <li>
              <strong> {i18n.t('enhanced-employee-experience')} </strong> {i18n.t('enhanced-employee-experience-description')} 
            </li>
            <li>
              <strong> {i18n.t('informed-decision-making')} </strong> {i18n.t('informed-decision-making-description')} 
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 'dynamic',
      title: i18n.t('customization'),
      image: '/images/solutions/solution-1.png',
      info: (
        <>
          {i18n.t('specialized-systems-for-other-fields')}
          <br />
          {i18n.t('car-showrooms')} <br /> {i18n.t('shipping-companies')} <br />
          {i18n.t('clinics')}
        </>
      ),
    },
  ];

  DATA.novaHrModules = [
    {
      id: 1,
      title: i18n.t('organizational-structure'),
      image: '/images/solutions/nova-hr/modules/module-8.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('organizational-structure-description')}
          </p>

          <p className='para'>
            {i18n.t('organizational-structure-roles')}:
          </p>

          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('job-and-responsibility-definition')}:</strong> <br /> {i18n.t('job-and-responsibility-definition-description')}
            </li>
            <li>
              <strong>{i18n.t('organizing-functional-reporting-structure')}:</strong> <br /> {i18n.t('organizing-functional-reporting-structure-description')}
            </li>
            <li>
              <strong>{i18n.t('facilitating-recruitment-and-job-guidance')}:</strong> <br /> {i18n.t('facilitating-recruitment-and-job-guidance-description')}
            </li>
            <li>
              <strong>{i18n.t('organizing-training-and-employee-development')}:</strong> <br /> {i18n.t('organizing-training-and-employee-development-description')}
            </li>
            <li>
              <strong>{i18n.t('determining-salary-and-benefits-structure')}:</strong> <br /> {i18n.t('determining-salary-and-benefits-structure-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 2,
      title: i18n.t('personnel'),
      image: '/images/solutions/nova-hr/modules/module-10.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('personnel-description')}
          </p>

          <p className='para'>
            {i18n.t('personnel-roles')}:
          </p>

          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('employee-information-management')}:</strong> <br /> {i18n.t('employee-information-management-description')}
            </li>
            <li>
              <strong>{i18n.t('recruitment-and-hiring-operations')}:</strong> <br /> {i18n.t('recruitment-and-hiring-operations-description')}
            </li>
            <li>
              <strong>{i18n.t('performance-management-and-evaluation')}:</strong> <br /> {i18n.t('performance-management-and-evaluation-description')}
            </li>
            <li>
              <strong>{i18n.t('training-and-development-management')}:</strong> <br /> {i18n.t('training-and-development-management-description')}
            </li>
            <li>
              <strong>{i18n.t('salary-and-benefits-management')}:</strong> <br /> {i18n.t('salary-and-benefits-management-description')}
            </li>
            <li>
              <strong>{i18n.t('labor-relations-management')}:</strong> <br /> {i18n.t('labor-relations-management-description')}
            </li>
            <li>
              <strong>{i18n.t('compliance-with-labor-laws-and-regulations')}:</strong> <br /> {i18n.t('compliance-with-labor-laws-and-regulations-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 3,
      title: i18n.t('attendance-and-leave'),
      image: '/images/solutions/nova-hr/modules/module-5.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('attendance-and-leave-description')}
          </p>

          <p className='para'>
            {i18n.t('attendance-and-leave-management-roles')}
          </p>

          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('attendance-recording')}:</strong> <br /> {i18n.t('attendance-recording-description')}
            </li>
            <li>
              <strong>{i18n.t('work-hours-tracking')}:</strong> <br /> {i18n.t('work-hours-tracking-description')}
            </li>
            <li>
              <strong>{i18n.t('leave-and-absence-management')}:</strong> <br /> {i18n.t('leave-and-absence-management-description')}
            </li>
            <li>
              <strong>{i18n.t('attendance-and-leave-reports')}:</strong> <br /> {i18n.t('attendance-and-leave-reports-description')}
            </li>
            <li>
              <strong>{i18n.t('compliance-with-policies-and-labor-laws')}:</strong> <br /> {i18n.t('compliance-with-policies-and-labor-laws-description')}
            </li>
            <li>
              <strong>{i18n.t('integration-with-payroll-and-benefits-systems')}:</strong> <br /> {i18n.t('integration-with-payroll-and-benefits-systems-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 4,
      title: i18n.t('payroll-and-benefits'),
      image: '/images/solutions/nova-hr/modules/module-7.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('payroll-and-benefits-description')}
          </p>

          <p className='para'>
            {i18n.t('payroll-and-benefits-functions')}:
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('salary-and-benefits-calculation')}:</strong> <br /> {i18n.t('salary-and-benefits-calculation-description')}
            </li>
            <li>
              <strong>{i18n.t('employee-data-management')}:</strong> <br /> {i18n.t('employee-data-management-description')}
            </li>
            <li>
              <strong>{i18n.t('reporting-and-financial-data-presentation')}:</strong> <br /> {i18n.t('reporting-and-financial-data-presentation-description')}
            </li>
            <li>
              <strong>{i18n.t('management-of-allowances-and-additional-benefits')}:</strong> <br /> {i18n.t('management-of-allowances-and-additional-benefits-description')}
            </li>
            <li>
              <strong>{i18n.t('compliance-with-financial-laws-and-regulations')}:</strong> <br /> {i18n.t('compliance-with-financial-laws-and-regulations-description')}
            </li>
            <li>
              <strong>{i18n.t('integration-with-comprehensive-management-systems')}:</strong> <br /> {i18n.t('integration-with-comprehensive-management-systems-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 5,
      title: i18n.t('employee-self-service'),
      image: '/images/solutions/nova-hr/modules/module-6.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('employee-self-service-description')}
          </p>

          <p className='para'>
            {i18n.t('employee-self-service-roles')}:
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('employee-profile-management')}:</strong> <br /> {i18n.t('employee-profile-management-description')}
            </li>
            <li>
              <strong>{i18n.t('leave-requests-and-approvals')}:</strong> <br /> {i18n.t('leave-requests-and-approvals-description')}
            </li>
            <li>
              <strong>{i18n.t('viewing-salaries-and-allowances')}:</strong> <br /> {i18n.t('viewing-salaries-and-allowances-description')}
            </li>
            <li>
              <strong>{i18n.t('accessing-important-reports-and-documents')}:</strong> <br /> {i18n.t('accessing-important-reports-and-documents-description')}
            </li>
            <li>
              <strong>{i18n.t('communication-and-interaction-with-management-and-colleagues')}:</strong> <br /> {i18n.t('communication-and-interaction-with-management-and-colleagues-description')}
            </li>
            <li>
              <strong>{i18n.t('submitting-suggestions-and-complaints')}:</strong> <br /> {i18n.t('submitting-suggestions-and-complaints-description')}
            </li>
            <li>
              <strong>{i18n.t('accessing-training-and-development')}:</strong> <br /> {i18n.t('accessing-training-and-development-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 6,
      title: i18n.t('performance-module'),
      image: '/images/solutions/nova-hr/modules/module-9.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('performance-module-description')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('setting-goals-and-performance-standards')}:</strong> <br /> {i18n.t('setting-goals-and-performance-standards-description')}
            </li>
            <li>
              <strong>{i18n.t('annual-or-periodic-performance-evaluation')}:</strong> <br /> {i18n.t('annual-or-periodic-performance-evaluation-description')}
            </li>
            <li>
              <strong>{i18n.t('providing-ongoing-reviews')}:</strong> <br /> {i18n.t('providing-ongoing-reviews-description')}
            </li>
            <li>
              <strong>{i18n.t('skills-development-and-continuous-improvement')}:</strong> <br /> {i18n.t('skills-development-and-continuous-improvement-description')}
            </li>
            <li>
              <strong>{i18n.t('linking-performance-to-rewards-and-promotions')}:</strong> <br /> {i18n.t('linking-performance-to-rewards-and-promotions-description')}
            </li>
            <li>
              <strong>{i18n.t('submitting-suggestions-and-complaints')}:</strong> <br /> {i18n.t('submitting-suggestions-and-complaints-description')}
            </li>
            <li>
              <strong>{i18n.t('data-analysis-and-management-reports')}:</strong> <br /> {i18n.t('data-analysis-and-management-reports-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 7,
      title: i18n.t('training-module') ,
      image: '/images/solutions/nova-hr/modules/module-3.png',
      content: (
        <>
         <p className='para'>
            {i18n.t('training-module-description')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('identifying-training-needs')}:</strong> <br /> {i18n.t('identifying-training-needs-description')}
            </li>
            <li>
              <strong>{i18n.t('designing-training-programs')}:</strong> <br /> {i18n.t('designing-training-programs-description')}
            </li>
            <li>
              <strong>{i18n.t('managing-training-courses')}:</strong> <br /> {i18n.t('managing-training-courses-description')}
            </li>
            <li>
              <strong>{i18n.t('training-performance-evaluation')}:</strong> <br /> {i18n.t('training-performance-evaluation-description')}
            </li>
            <li>
              <strong>{i18n.t('progress-tracking-and-reporting')}:</strong> <br /> {i18n.t('progress-tracking-and-reporting-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 8,
      title: i18n.t('recruitment-module'),
      image: '/images/solutions/nova-hr/modules/module-4.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('recruitment-module-description')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('job-posting-and-application-management')}:</strong> <br /> {i18n.t('job-posting-and-application-management-description')}
            </li>
            <li>
              <strong>{i18n.t('management-of-waiting-lists-and-candidates')}:</strong> <br /> {i18n.t('management-of-waiting-lists-and-candidates-description')}
            </li>
            <li>
              <strong>{i18n.t('candidate-assessment-and-interviews')}:</strong> <br /> {i18n.t('candidate-assessment-and-interviews-description')}
            </li>
            <li>
              <strong>{i18n.t('communication-with-candidates')}:</strong> <br /> {i18n.t('communication-with-candidates-description')}
            </li>
            <li>
              <strong>{i18n.t('management-of-job-offers-and-comparisons')}:</strong> <br /> {i18n.t('management-of-job-offers-and-comparisons-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 9,
      title: i18n.t('workflow-module'),
      image: '/images/solutions/nova-hr/modules/module-2.png',
      content: (
        <>
          <p className='para'> {i18n.t('workflow-module-description')}
          </p>

          <p className='para'>
          {i18n.t('workflow-module-roles')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('coordinating-recruitment-and-guidance-processes')}:</strong> <br /> {i18n.t('coordinating-recruitment-and-guidance-processes-description')}
            </li>
            <li>
              <strong>{i18n.t('facilitating-approval-and-review-processes')}:</strong> <br /> {i18n.t('facilitating-approval-and-review-processes-description')}
            </li>
            <li>
              <strong>{i18n.t('guiding-training-and-development-processes')}:</strong> <br /> {i18n.t('guiding-training-and-development-processes-description')}
            </li>
            <li>
              <strong>{i18n.t('monitoring-processes-and-identifying-alternative-paths')}:</strong> <br /> {i18n.t('monitoring-processes-and-identifying-alternative-paths-description')}
            </li>
            <li>
              <strong>{i18n.t('notifications-and-alerts')}:</strong> <br /> {i18n.t('notifications-and-alerts-description')}
            </li>
            <li>
              <strong>{i18n.t('generating-reports-and-analyses')}:</strong> <br /> {i18n.t('generating-reports-and-analyses-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 10,
      title: 'GPS Attendance ',
      image: '/images/solutions/nova-hr/modules/module-1.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('gps-attendance-module-description')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('record-attendance-and-departure-accurately')}:</strong> <br /> {i18n.t('record-attendance-and-departure-accurately-description')}
            </li>
            <li>
              <strong>{i18n.t('monitor-presence-and-activity')}:</strong> <br /> {i18n.t('monitor-presence-and-activity-description')}
            </li>
            <li>
              <strong>{i18n.t('increase-efficiency-and-security')}:</strong> <br /> {i18n.t('increase-efficiency-and-security-description')}
            </li>
            <li>
              <strong>{i18n.t('provide-accurate-data-for-payroll-and-accounting')}:</strong> <br /> {i18n.t('provide-accurate-data-for-payroll-and-accounting-description')}
            </li>
          </ul>

        </>
      ),
    },
  ];

  DATA.erpnextModules = [
    {
      id: 1,
      title: i18n.t('accounting') ,
      image: '/images/solutions/next-erp/modules/module-7.png',
      content: (
        <>
          <p className='para'> {i18n.t('accounting-details')} 
          </p>

          <p className='para'> {i18n.t('the-functions-of-the-accounts-module')} </p>
          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('accounts-receivable-and-payable-management')}</strong> <br /> 
              {i18n.t('accounts-receivable-and-payable-management-description')}
            </li>
            <li>
              <strong>{i18n.t('invoices-and-payments-management')}</strong> <br /> 
              {i18n.t('invoices-and-payments-management-description')}
            </li>
            <li>
              <strong>{i18n.t('bank-accounts-management')}</strong> <br /> 
              {i18n.t('bank-accounts-management-description')}
            </li>
            <li>
              <strong>{i18n.t('payroll-and-benefits-management')}</strong> <br /> {i18n.t('payroll-and-benefits-management-description')}
            </li>
            <li>
              <strong>{i18n.t('financial-reports')}</strong> <br /> {i18n.t('financial-reports-description')}
            </li>
            <li>
              <strong>{i18n.t('tax-management-and-financial-compliance')}</strong> <br /> {i18n.t('tax-management-and-financial-compliance-description')}
            </li>
            <li>
              <strong>{i18n.t('fixed-assets-management')}</strong> <br /> {i18n.t('fixed-assets-management-description')}
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      title: i18n.t('buying') ,
      image: '/images/solutions/next-erp/modules/module-10.png',
      content: (
        <>
          <p className='para'> {i18n.t('buying-details')} </p>

          <p className='para'> {i18n.t('the-key-functions-of-the-purchasing-module')} </p>

          <ul className='describe-list'>
            <li> {i18n.t('material-requisition')} </li>
            <li> {i18n.t('purchase-order')} </li>
            <li> {i18n.t('purchase-invoice')} </li>
            <li> {i18n.t('request-for-quotation')} </li>
            <li> {i18n.t('supplier-quotation')} </li>
            <li> {i18n.t('supplier')} </li>
            <li> {i18n.t('supplier-group')} </li>
            <li> {i18n.t('purchase-taxes')} </li>
            <li> {i18n.t('key-reports')} </li>
          </ul>
        </>
      ),
    },
    {
      id: 3,
      title: i18n.t('sales'),
      image: '/images/solutions/next-erp/modules/module-8.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('sales-details')}
          </p>

          <p className='para'>
            {i18n.t('the-functions-of-the-sales-module')}
          </p>

          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('customer-and-relationship-management')}:</strong> <br /> {i18n.t('customer-and-relationship-management-description')}
            </li>
            <li>
              <strong>{i18n.t('order-and-sales-management')}:</strong> <br /> {i18n.t('order-and-sales-management-description')}
            </li>
            <li>
              <strong>{i18n.t('data-analysis-and-sales-reports')}:</strong> <br /> {i18n.t('data-analysis-and-sales-reports-description')}
            </li>
            <li>
              <strong>{i18n.t('pricing-and-discount-management')}:</strong> <br /> {i18n.t('pricing-and-discount-management-description')}
            </li>
            <li>
              <strong>{i18n.t('inventory-and-distribution-management')}:</strong> <br /> {i18n.t('inventory-and-distribution-management-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 4,
      title: i18n.t('stock'),
      image: '/images/solutions/next-erp/modules/module-9.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('stock-details')}
          </p>

          <p className='para'>
            {i18n.t('the-functions-of-the-inventory-module')}
          </p>

          <ul className='describe-list'>
            <li>
              <strong>{i18n.t('material-and-product-management')}:</strong> <br /> {i18n.t('material-and-product-management-description')}
            </li>
            <li>
              <strong>{i18n.t('periodic-and-perpetual-inventory-management')}:</strong> <br /> {i18n.t('periodic-and-perpetual-inventory-management-description')}
            </li>
            <li>
              <strong>{i18n.t('reorder-level-and-demand-management')}:</strong> <br /> {i18n.t('reorder-level-and-demand-management-description')}
            </li>
            <li>
              <strong>{i18n.t('shipping-and-distribution-management')}:</strong> <br /> {i18n.t('shipping-and-distribution-management-description')}
            </li>
            <li>
              <strong>{i18n.t('cost-monitoring')}:</strong> <br /> {i18n.t('cost-monitoring-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 5,
      title: i18n.t('fixed-assets'),
      image: '/images/solutions/next-erp/modules/module-2.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('fixed-assets-details')}
          </p>

          <p className='para'>
            {i18n.t('the-functions-of-the-assets-module')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('asset-registration')}:</strong> <br /> {i18n.t('asset-registration-description')}
            </li>
            <li>
              <strong>{i18n.t('maintenance-management')}:</strong> <br /> {i18n.t('maintenance-management-description')}
            </li>
            <li>
              <strong>{i18n.t('asset-valuation')}:</strong> <br /> {i18n.t('asset-valuation-description')}
            </li>
            <li>
              <strong>{i18n.t('usage-tracking')}:</strong> <br /> {i18n.t('usage-tracking-description')}
            </li>
            <li>
              <strong>{i18n.t('financial-reporting')}:</strong> <br /> {i18n.t('financial-reporting-description')}
            </li>
            <li>
              <strong>{i18n.t('asset-lifecycle-management')}:</strong> <br /> {i18n.t('asset-lifecycle-management-description')}
            </li>
          </ul>

        </>
      ),
    },
    {
      id: 6,
      title: i18n.t('crm'),
      image: '/images/solutions/next-erp/modules/module-3.png',
      content: (
        <>
          <p className='para'>{i18n.t('crm-details')}
          </p>

          <ul className='describe-list'>
            <li> {i18n.t('lead')}</li>
            <li> {i18n.t('opportunity')}</li>
            <li> {i18n.t('customer')}</li>
            <li> {i18n.t('communication')}</li>
            <li> {i18n.t('lead-source')}</li>
            <li> {i18n.t('contract')}</li>
            <li> {i18n.t('appointments')}</li>
            <li> {i18n.t('advertising-campaigns')} </li>
          </ul>
        </>
      ),
    },
    {
      id: 7,
      title: i18n.t('manufacturing'),
      image: '/images/solutions/next-erp/modules/module-1.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('manufacturing-details')}
          </p>

          <p className='para'>
            {i18n.t('the-functions-of-the-manufacturing-module')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('resource-planning')}:</strong> <br /> {i18n.t('resource-planning-description')}
            </li>
            <li>
              <strong>{i18n.t('production-scheduling-management')}:</strong> <br /> {i18n.t('production-scheduling-management-description')}
            </li>
            <li>
              <strong>{i18n.t('progress-tracking')}:</strong> <br /> {i18n.t('progress-tracking-description')}
            </li>
            <li>
              <strong>{i18n.t('quality-management')}:</strong> <br /> {i18n.t('quality-management-description')}
            </li>
            <li>
              <strong>{i18n.t('inventory-management')}:</strong> <br /> {i18n.t('inventory-management-description')}
            </li>
            <li>
              <strong>{i18n.t('cost-tracking')}:</strong> <br /> {i18n.t('cost-tracking-description')}
            </li>
          </ul>

          <p className='para'>
            {i18n.t('using-the-manufacturing-module')}
          </p>

        </>
      ),
    },
    {
      id: 8,
      title: i18n.t('point-of-sale'),
      image: '/images/solutions/next-erp/modules/module-12.png',
      content: (
        <>
          {i18n.t('point-of-sale-details')}
        </>
      ),
    },
    {
      id: 18,
      title: i18n.t('e-invoice-e-receipt'),
      image: '/images/solutions/next-erp/modules/module-6.png',
      content: (
        <>
          <h5 className='mt-4'> {i18n.t('electronic-invoice')} </h5>

          <p>
          {i18n.t('electronic-invoice-description')}
          </p>

          <h5 className='mt-4'>{i18n.t('electronic-receipt')}</h5>

          <p>
          {i18n.t('electronic-receipt-description')}
          </p>

          <strong>
          {i18n.t('our-team-ensures-compliance')}
          </strong>
        </>
      ),
    },
    {
      id: 9,
      title: i18n.t('property-management'),
      image: '/images/solutions/next-erp/modules/module-4.png',
      content: (
        <>
         {i18n.t('property-management-details')}
        </>
      ),
    },
    {
      id: 10,
      title: i18n.t('project-and-contract-management'),
      image: '/images/solutions/next-erp/modules/module-11.png',
      content: (
        <>
          <p className='para'>
            {i18n.t('project-and-contract-management-description')}
          </p>

          <ul className='describe-list mb-5'>
            <li>
              <strong>{i18n.t('project-planning')}:</strong> <br /> {i18n.t('project-planning-description')}
            </li>
            <li>
              <strong>{i18n.t('resource-and-budget-management')}:</strong> <br /> {i18n.t('resource-and-budget-management-description')}
            </li>
            <li>
              <strong>{i18n.t('progress-and-performance-tracking')}:</strong> <br /> {i18n.t('progress-and-performance-tracking-description')}
            </li>
            <li>
              <strong>{i18n.t('human-resource-planning')}:</strong> <br /> {i18n.t('human-resource-planning-description')}
            </li>
            <li>
              <strong>{i18n.t('enhanced-coordination-and-collaboration')}:</strong> <br /> {i18n.t('enhanced-coordination-and-collaboration-description')}
            </li>
          </ul>

          <p className='para'>
            {i18n.t('overall-benefit')}
          </p>

        </>
      ),
    },
    {
      id: 11,
      title: i18n.t('fleet-management'),
      image: '/images/solutions/next-erp/modules/module-4.png',
      content: (
        <>
        <p className='para'>
          {i18n.t('fleet-management-description')}
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>{i18n.t('improving-efficiency')}:</strong> <br /> {i18n.t('improving-efficiency-description')}
          </li>
          <li>
            <strong>{i18n.t('cost-reduction')}:</strong> <br /> {i18n.t('cost-reduction-description')}
          </li>
          <li>
            <strong>{i18n.t('increasing-safety')}:</strong> <br /> {i18n.t('increasing-safety-description')}
          </li>
          <li>
            <strong>{i18n.t('improving-responsiveness')}:</strong> <br /> {i18n.t('improving-responsiveness-description')}
          </li>
          <li>
            <strong>{i18n.t('providing-reports-and-analytics')}:</strong> <br /> {i18n.t('providing-reports-and-analytics-description')}
          </li>
        </ul>

        <p className='para'>
          {i18n.t('role-in-logistics-management')}
        </p>

      </>
      ),
    },
  ];
};

updateI18nTranslation();

i18n.on('languageChanged', () => {
  updateI18nTranslation();
});

const SPONSOR = [
  '/images/sponsor/sponsor-1.png',
  '/images/sponsor/sponsor-2.png',
  '/images/sponsor/sponsor-3.png',
  '/images/sponsor/sponsor-4.png',
  '/images/sponsor/sponsor-5.png',
  '/images/sponsor/sponsor-6.png',
  '/images/sponsor/sponsor-7.png',
  '/images/sponsor/sponsor-86.png',
  '/images/sponsor/sponsor-8.png',
  '/images/sponsor/sponsor-9.png',
  '/images/sponsor/sponsor-10.png',
  '/images/sponsor/sponsor-11.png',
  '/images/sponsor/sponsor-12.png',
  '/images/sponsor/sponsor-13.png',
  '/images/sponsor/sponsor-14.png',
  '/images/sponsor/sponsor-15.png',
  '/images/sponsor/sponsor-16.png',
  '/images/sponsor/sponsor-17.png',
  '/images/sponsor/sponsor-18.png',
  '/images/sponsor/sponsor-19.png',
  '/images/sponsor/sponsor-20.png',
  '/images/sponsor/sponsor-21.png',
  '/images/sponsor/sponsor-22.png',
  '/images/sponsor/sponsor-23.png',
  '/images/sponsor/sponsor-24.png',
  '/images/sponsor/sponsor-25.png',
  '/images/sponsor/sponsor-26.png',
  '/images/sponsor/sponsor-27.png',
  '/images/sponsor/sponsor-28.png',
  '/images/sponsor/sponsor-29.png',
  '/images/sponsor/sponsor-30.png',
  '/images/sponsor/sponsor-31.png',
  '/images/sponsor/sponsor-32.png',
  '/images/sponsor/sponsor-33.png',
  '/images/sponsor/sponsor-34.png',
  '/images/sponsor/sponsor-35.png',
  '/images/sponsor/sponsor-36.png',
  '/images/sponsor/sponsor-37.png',
  '/images/sponsor/sponsor-38.png',
  '/images/sponsor/sponsor-39.png',
  '/images/sponsor/sponsor-40.png',
  '/images/sponsor/sponsor-41.png',
  '/images/sponsor/sponsor-42.png',
  '/images/sponsor/sponsor-43.png',
  '/images/sponsor/sponsor-44.png',
  '/images/sponsor/sponsor-45.png',
  '/images/sponsor/sponsor-46.png',
  '/images/sponsor/sponsor-47.png',
  '/images/sponsor/sponsor-48.png',
  '/images/sponsor/sponsor-49.png',
  '/images/sponsor/sponsor-50.png',
  '/images/sponsor/sponsor-51.png',
  '/images/sponsor/sponsor-52.png',
  '/images/sponsor/sponsor-53.png',
  '/images/sponsor/sponsor-54.png',
  '/images/sponsor/sponsor-55.png',
  '/images/sponsor/sponsor-56.png',
  '/images/sponsor/sponsor-57.png',
  '/images/sponsor/sponsor-58.png',
  '/images/sponsor/sponsor-59.png',
  '/images/sponsor/sponsor-60.png',
  '/images/sponsor/sponsor-61.png',
  '/images/sponsor/sponsor-62.png',
  '/images/sponsor/sponsor-63.png',
  '/images/sponsor/sponsor-64.png',
  '/images/sponsor/sponsor-65.png',
  '/images/sponsor/sponsor-66.png',
  '/images/sponsor/sponsor-67.png',
  '/images/sponsor/sponsor-68.png',
  '/images/sponsor/sponsor-69.png',
  '/images/sponsor/sponsor-70.png',
  '/images/sponsor/sponsor-71.png',
  '/images/sponsor/sponsor-72.png',
  '/images/sponsor/sponsor-73.png',
  '/images/sponsor/sponsor-74.png',
  '/images/sponsor/sponsor-75.png',
  '/images/sponsor/sponsor-76.png',
  '/images/sponsor/sponsor-77.png',
  '/images/sponsor/sponsor-78.png',
  '/images/sponsor/sponsor-79.png',
  '/images/sponsor/sponsor-80.png',
  '/images/sponsor/sponsor-81.png',
  '/images/sponsor/sponsor-82.png',
  '/images/sponsor/sponsor-83.png',
  '/images/sponsor/sponsor-84.png',
  '/images/sponsor/sponsor-85.png',
];

const HOMEPAGE_SPONSOR = [
  '/images/sponsor/sponsor-1.png',
  '/images/sponsor/sponsor-2.png',
  '/images/sponsor/sponsor-3.png',
  '/images/sponsor/sponsor-4.png',
  '/images/sponsor/sponsor-86.png',
  '/images/sponsor/sponsor-6.png',
  '/images/sponsor/sponsor-7.png',
  '/images/sponsor/sponsor-8.png',
  '/images/sponsor/sponsor-9.png',
  '/images/sponsor/sponsor-10.png',
  '/images/sponsor/sponsor-11.png',
  '/images/sponsor/sponsor-12.png',
  '/images/sponsor/sponsor-13.png',
  '/images/sponsor/sponsor-14.png',
  '/images/sponsor/sponsor-15.png',
  '/images/sponsor/sponsor-16.png',
  '/images/sponsor/sponsor-17.png',
  '/images/sponsor/sponsor-18.png',
  '/images/sponsor/sponsor-19.png',
  '/images/sponsor/sponsor-5.png',
];

const TESTIMONIAL = [
  {
    id: '1',
    name: 'Engy Khaled - HR MANAGER JIT',
    image: '/images/testimonial/jit.jpeg',
    job: '',
    description: (
      <>
        Almost number one for Business solutions at Middle East we are so proud
        for our business together Also, special appreciation for your amazing
        Team members
        <br />
        Mr. Ahmed El Tokhay numbers as an HR Consultant on software ,Mrs. Marina
        Atef supportive and smart technical HR ,Mr. Mahmoud ossman best sales
        manager all the way , Mr. Youssef talent developer
        <br />
        And finally, one of the best Mr. Mohamed Wessam CEO
        <br />
        Really wishing you all the success Dynamic Business Solution keep up the
        great work and keep doing your best
      </>
    ),
    dir: 'left',
  },
  {
    id: '2',
    name: 'محمد صبحي - مدير ادارة تكنولوجيا المعلومات',
    image: '/images/testimonial/new-modern.png',
    job: 'شركة مودرن بلاست - نيو مودرن بلاست',
    description: (
      <>
        في البدايه أقدم لكم أجمل عبارات الشكر والاحترام والتقدير لشركتكم الموقره
        علي الدعم الكامل الاداري والفني لادارة برنامج NEXT HR . <br />
        واختص بالشكر المهندسه / سهيله على الجهد الكبير والاهتمام الواضح حتي
        اتمام البرنامج واستخراج المرتبات لشركتنا ( مودرن بلاست - نيو مودرن بلاست
        ) . لكم من جزيل الشكر والاحترام , دائما في تقدم ونجاح ان شاء الله .
      </>
    ),
  },
];

export { HOMEPAGE_SPONSOR, SPONSOR, TESTIMONIAL };

export default DATA;
