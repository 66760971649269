import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import AboutUsCounter from '../components/common/AboutUsCounter';
import Breadcrumb from '../components/common/Breadcrumb';
import CONFIG from '../core/config';
import { initPhoneNumber } from '../utils';
import emailjs from '@emailjs/browser';
import { UseI18nContext } from '../context/i18n';

const SOLUTION_OPTIONS = [
  {
    value: 'ERPNext',
    label: 'ERPNext',
  },
  {
    value: 'Nova HR',
    label: 'Nova HR',
  },
  {
    value: 'Car System',
    label: 'Car System',
  },
  {
    value: 'Shipping System',
    label: 'Shipping System',
  },
  {
    value: 'Mobile App',
    label: 'Mobile App',
  },
  {
    value: 'Healthcare',
    label: 'Healthcare',
  },
];

function Contact() {
  const { t, i18n } = useTranslation();
  const i18nContext = UseI18nContext();
  const captchaRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [formInfo, setFormInfo] = useState({
    name: '',
    email: '',
    msg: '',
    solution: '',
    mobile: '',
    company: '',
  });

  const onInputChange = (evt) => {
    setFormInfo({
      ...formInfo,
      [evt.target.name]: evt.target.value,
    });
  };

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const token = captchaRef.current?.getValue();

    if (!token) {
      toast.error(t('please-verify-that-you-are-not-a-bot'));
      return;
    }

    if (isNaN(formInfo.mobile)) {
      toast.error(t('please-enter-a-numeric-phone-number'));
      return;
    }

    setIsLoading(true);

    const templateParams = {
      from_name: formInfo.name,
      to_name: 'Dynamic Egypt',
      mobile: formInfo.mobile,
      email: formInfo.email,
      solution: formInfo.solution,
      msg: formInfo.msg,
      company: formInfo.company,
    };

    await emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID ?? '',
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID ?? '',
        templateParams,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast.success(t('thank-you-we-received-your-message'));
          setFormInfo({
            name: '',
            email: '',
            msg: '',
            solution: '',
            mobile: '',
            company: '',
          });

          captchaRef.current?.reset();
        },
        (err) => {
          toast.error(t('error-please-try-again'));
          console.log(err);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb pageName={t('contact-us')} pageTitle={t('contact-us')} />

      <div className='contact-section pt-120 pb-120'>
        <ToastContainer position='bottom-center' rtl />

        <div className='container'>
          <div className='row pb-120 mb-70 g-4 d-flex justify-content-center'>
            <div className='col-lg-4 col-md-6 col-sm-8'>
              <div
                className='contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
              >
                <div className='icon'>
                  <i className='bi bi-geo-alt' />
                </div>
                <div className='text'>
                  <h4>{t('location')}</h4>
                  <p>
                    {i18n.language === 'en'
                      ? CONFIG.englishAddress
                      : CONFIG.address}
                  </p>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-8'>
              <div
                className='contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.4s'
              >
                <div className='icon'>
                  <i className='bx bx-phone-call' />
                </div>

                <div className='text'>
                  <h4>{t('phone')}</h4>
                  <a
                    href={`tel:${initPhoneNumber(CONFIG.phone)}`}
                    dir='ltr'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {CONFIG.phone}
                  </a>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-sm-8'>
              <div
                className='contact-signle hover-border1 d-flex flex-row align-items-center wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.6s'
              >
                <div className='icon'>
                  <i className='bx bx-envelope' />
                </div>

                <div className='text'>
                  <h4>{t('email')}</h4>
                  <a href={`mailto:${CONFIG.email}`}>{CONFIG.email}</a>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div
                className='form-wrapper wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.2s'
              >
                <div className='form-title2'>
                  <h3>{t('get-in-touch')}</h3>
                </div>

                <form onSubmit={onFormSubmit}>
                  <div className='row'>
                    <div className='col-xl-6 col-lg-12 col-md-6'>
                      <div className='form-inner'>
                        <input
                          type='text'
                          placeholder={t('your-name')}
                          value={formInfo.name}
                          name='name'
                          required
                          onChange={onInputChange}
                        />
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-12 col-md-6'>
                      <div className='form-inner'>
                        <input
                          type='email'
                          placeholder={t('your-email')}
                          value={formInfo.email}
                          onChange={onInputChange}
                          name='email'
                          required
                        />
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-12 col-md-6'>
                      <div className='form-inner'>
                        <input
                          type='tel'
                          placeholder={t('your-phone')}
                          value={formInfo.mobile}
                          required
                          name='mobile'
                          onChange={onInputChange}
                        />
                      </div>
                    </div>

                    <div className='col-xl-6 col-lg-12 col-md-6'>
                      <Select
                        isRtl={i18nContext.language.code === 'ar'}
                        name='solution'
                        required
                        value={
                          formInfo.solution
                            ? SOLUTION_OPTIONS.find(
                                (item) => item.value === formInfo.solution
                              )
                            : null
                        }
                        isSearchable={false}
                        className='basic-single'
                        placeholder={t('subject')}
                        classNamePrefix='select-contact'
                        options={SOLUTION_OPTIONS}
                        onChange={(item) =>
                          setFormInfo({
                            ...formInfo,
                            solution: item?.value ?? '',
                          })
                        }
                      />
                    </div>

                    <div className='col-xl-6 col-lg-12 col-md-6'>
                      <div className='form-inner'>
                        <input
                          type='text'
                          placeholder={t('your-company')}
                          value={formInfo.company}
                          onChange={onInputChange}
                          name='company'
                          required
                        />
                      </div>
                    </div>

                    <div className='col-12'>
                      <textarea
                        name='msg'
                        placeholder={t('write-message')}
                        rows={12}
                        onChange={onInputChange}
                        value={formInfo.msg}
                      />
                    </div>

                    <div className='col-12'>
                      {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          ref={captchaRef}
                        />
                      )}
                    </div>

                    <div className='col-12'>
                      <button
                        type='submit'
                        disabled={isLoading}
                        className='eg-btn btn--primary btn--md form--btn'
                      >
                        {isLoading ? t('loading') : t('send-message')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pb-120'>
        <AboutUsCounter />
      </div>
    </>
  );
}

export default Contact;
