function scrollToTop() {
  return window.scrollTo({ top: 0, behavior: 'smooth' });
}

// phone number: remove plus and space
function initPhoneNumber(num) {
  return num.replace(/\s/g, '').replace('+', '');
}

function toArabicDigits(number) {
  const id = '٠١٢٣٤٥٦٧٨٩';
  return number.toString().replace(/\d/g, (w) => id[+w]);
}


export { scrollToTop, initPhoneNumber, toArabicDigits };
