import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutUsCounter from '../components/common/AboutUsCounter';
import Breadcrumb from '../components/common/Breadcrumb';
import Testimonial from '../components/common/Testimonial';
import WhoWeAre from '../components/common/WhoWeAre';
import { UseI18nContext } from '../context/i18n';
import DATA from '../core/data';
import { toArabicDigits } from '../utils';

function About() {
  const { t } = useTranslation();
  const i18nContext = UseI18nContext();

  return (
    <>
      <Breadcrumb pageName={t('about-us')} pageTitle={t('about-us')} />

      <WhoWeAre />

      <div className='choose-us-section pt-120 pb-120'>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-8 col-xl-6'>
              <div className='section-title1'>
                <h2>{t('why-choose-us')}</h2>
                <p className='mb-0'>
                  {t(
                    'we-provide-a-combination-of-expertise-innovation-personalized-customer-service-a-commitment-to-delivering-results-and-a-focus-on-building-long-term-relationships-making-it-a-trusted-and-valuable-partner-for-businesses-seeking-effective-software-solutions'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-center g-4'>
            {DATA.whyChooseUs.map((item, index) => {
              const number = index + 1 > 9 ? index + 1 : '0' + (index + 1);

              return (
                <div className='col-lg-4 col-md-6 col-sm-10' key={item.id}>
                  <div
                    className='single-feature hover-border1 wow fadeInDown'
                    data-wow-duration='1.5s'
                    data-wow-delay={`${(0.2 * index) % 3}s`}
                  >
                    <span className='sn'>
                      {i18nContext.language.code === 'ar'
                        ? toArabicDigits(number)
                        : number}
                    </span>
                    <div className='icon'>
                      <img
                        src={item.image}
                        width={68}
                        height={68}
                        alt={t(item.title)}
                      />
                    </div>
                    <div className='content'>
                      <h5>{t(item.title)}</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Testimonial />

      <div className='pb-120'>
        <AboutUsCounter />
      </div>
    </>
  );
}

export default About;
