import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/Breadcrumb';
import DATA from '../../core/data';
import { scrollToTop } from '../../utils';
import ErrorPage from '../ErrorPage';
import { useTranslation } from 'react-i18next';

function Dynamic() {
  const { t } = useTranslation();

  const solution = DATA.solutions.find((solution) => solution.id === 'dynamic');

  if (!solution) {
    return <ErrorPage />;
  }

  return (
    <>
      <Breadcrumb pageName={solution.title} pageTitle={solution.title} />

      <div className='auction-details-section pt-120 pb-50'>
        <div className='container'>
          <div className='row justify-content-center g-4 pb-120'>
            <div className='col-md-7'>
              <div
                className='tab-content mb-4 wow fadeInUp'
                data-wow-duration='1.5s'
                data-wow-delay='.4s'
              >
                <div className='tab-pane big-image fade' id='gallery-img1'>
                  <img
                    alt='images'
                    src='/images/solutions/dynamic/gallery/image-1.png'
                    className='img-fluid w-100'
                  />
                </div>
                <div className='tab-pane big-image fade' id='gallery-img2'>
                  <img
                    alt='images'
                    src='/images/solutions/dynamic/gallery/image-2.png'
                    className='img-fluid w-100'
                  />
                </div>
                <div
                  className='tab-pane big-image fade  show active'
                  id='gallery-img3'
                >
                  <img
                    alt='images'
                    src='/images/solutions/dynamic/gallery/image-3.png'
                    className='img-fluid w-100'
                  />
                </div>
              </div>

              <ul
                className='nav small-image-list d-flex flex-row justify-content-center gap-4  wow fadeInDown'
                data-wow-duration='1.5s'
                data-wow-delay='.4s'
              >
                <li className='nav-item'>
                  <div
                    id='details-img1'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img1'
                    aria-controls='gallery-img1'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/dynamic/gallery/image-1.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
                <li className='nav-item'>
                  <div
                    id='details-img2'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img2'
                    aria-controls='gallery-img2'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/dynamic/gallery/image-2.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
                <li className='nav-item'>
                  <div
                    id='details-img3'
                    data-bs-toggle='pill'
                    data-bs-target='#gallery-img3'
                    aria-controls='gallery-img3'
                  >
                    <img
                      alt='images'
                      src='/images/solutions/dynamic/gallery/image-3.png'
                      className='img-fluid'
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='container describe-content mb-5'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('showroom-management')}</h2>
          <span></span>
        </div>

        <p className='para'>{t('showroom-management-description')} </p>

        <p className='para'>
          {t(
            'monitoring-sales-operations-and-analyzing-performance-description'
          )}
        </p>

        <ul className='describe-list'>
          <li>
            <strong> {t('marketing')}:</strong>
            <p className='mb-2'>{t('marketing-description')} </p>
          </li>
          <li>
            <strong>{t('inventory-management')}:</strong>
            <p className='mb-2'>{t('inventory-management-description')}</p>
          </li>
          <li>
            <strong>{t('customer-relationship-management-crm')} :</strong>
            <p className='mb-2'>
              {t('customer-relationship-management-crm-description')}
            </p>
          </li>
        </ul>

        <p className='para'>{t('comprehensive-solution-description')} </p>
      </div>

      <div className='container describe-content mb-5'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('shipping-management')}:</h2>
          <span></span>
        </div>

        <p className='para'>{t('shipping-management-description')} </p>

        <p className='para'>
          {t(
            'the-shipping-company-management-system-includes-a-range-of-functions-and-features-such-as'
          )}
        </p>

        <ul className='describe-list'>
          <li>
            <strong>{t('shipment-tracking')}:</strong> <br />
            {t('shipment-tracking-description')}
          </li>
          <li>
            <strong>{t('planning-and-scheduling')}:</strong> <br />
            {t('planning-and-scheduling-description')}
          </li>
          <li>
            <strong>{t('inventory-management')} :</strong> <br />
            {t('inventory-management-description')}
          </li>

          <li>
            <strong>{t('communication-and-customer-service')} :</strong> <br />
            {t('communication-and-customer-service-description')}
          </li>
        </ul>

        <p className='para'>{t('scope-and-features-description')} </p>
      </div>

      <div className='container describe-content mb-5'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('property-management')}</h2>
          <span></span>
        </div>

        <p className='para'>{t('property-management-details')}</p>
      </div>

      <div className='container describe-content mb-5'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('project-and-contract-management')}</h2>
          <span></span>
        </div>

        <p className='para'>
          {t('project-and-contract-management-description')}
        </p>

        <ul className='describe-list mb-5'>
          <li>
            <strong>{t('project-planning')}:</strong> <br />
            {t('project-planning-description')}
          </li>
          <li>
            <strong>{t('resource-and-budget-management')}:</strong> <br />
            {t('resource-and-budget-management-description')}
          </li>
          <li>
            <strong>{t('progress-and-performance-tracking')}:</strong> <br />
            {t('progress-and-performance-tracking-description')}
          </li>
          <li>
            <strong>{t('human-resource-planning')}:</strong> <br />
            {t('human-resource-planning-description')}
          </li>
          <li>
            <strong>{t('enhanced-coordination-and-collaboration')}:</strong>
            <br /> {t('enhanced-coordination-and-collaboration-description')}
          </li>
        </ul>

        <p className='para'>{t('overall-benefit')}</p>
      </div>

      <div className='container describe-content mb-120'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('electronic-invoice')}</h2>
          <span></span>
        </div>

        <p>{t('electronic-invoice-description')}</p>

        <div className='sidebar-widget-title mb-3  mt-5 pt-3'>
          <h2>{t('electronic-receipt')}</h2>
          <span></span>
        </div>

        <p>{t('electronic-receipt-description')}</p>

        <strong>{t('our-team-ensures-compliance')}</strong>
      </div>

      <div className='container describe-content pt-5 mt-5 pb-120'>
        <div className='sidebar-widget-title mb-3  '>
          <h2>{t('healthcare-management')} : </h2>
          <span></span>
        </div>

        <p className='para'> {t('healthcare-management-description')} </p>

        <ul className='describe-list'>
          <li>
            <strong>{t('scheduling-and-appointment-management')}:</strong>
            <br /> {t('scheduling-and-appointment-management-description')}
          </li>
          <li>
            <strong>{t('medical-records-management')}:</strong> <br />
            {t('medical-records-management-description')}
          </li>
          <li>
            <strong>{t('billing-and-payments')} :</strong> <br />
            {t('billing-and-payments-description')}
          </li>

          <li>
            <strong>{t('communication')} :</strong> <br />
            {t('communication-description')}
          </li>

          <li>
            <strong>{t('reports-and-analytics')} :</strong> <br />
            {t('reports-and-analytics-description')}
          </li>
        </ul>

        <p className='para pb-60'>
          {t('the-clinic-management-system-is-essential-tool')}
        </p>

        <Link
          to='/contact'
          onClick={scrollToTop}
          className='eg-btn btn--primary3 btn--lg wow fadeInUp'
          data-wow-duration='2s'
          data-wow-delay='0.8s'
        >
          {t('request-a-demo-version')}
        </Link>
      </div>
    </>
  );
}

export default Dynamic;
